import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import ApiDataProvider from "./provider/apiData";
import { BrowserRouter } from "react-router-dom";
import { KunciProvider } from "kunci-react";

import "./index.css";
import TaskProvider from "./provider/task";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <BrowserRouter>
    <KunciProvider apiUrl={process.env.REACT_APP_API_URL!}>
      <ApiDataProvider>
        <TaskProvider>
          <App />
        </TaskProvider>
      </ApiDataProvider>
    </KunciProvider>
  </BrowserRouter>,
);
