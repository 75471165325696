import { EPosition } from "./constants";

const brands: { brand: string; positions: EPosition[] }[] = [
  {
    brand: "Toto",
    positions: [
      EPosition["Next to dartsbord, wall behind"],
      EPosition["On shirt"],
      EPosition["Boarding (behind player, podium or under stage)"],
      EPosition["Microphones"],
      EPosition["Tables behind dartsplayers"],
      EPosition["All the other"],
    ],
  },
  {
    brand: "Dart Kings",
    positions: [
      EPosition["Next to dartsbord, wall behind"],
      EPosition["On shirt"],
      EPosition["Boarding (behind player, podium or under stage)"],
      EPosition["Microphones"],
      EPosition["Tables behind dartsplayers"],
      EPosition["All the other"],
    ],
  },
];

export default brands;
