import React, { Dispatch, SetStateAction } from "react";
import { emptyFn } from "../inc/data";

type TResults = {
  [time: string]: {
    [brand: string]: {
      [position: string]: number;
    };
  };
};

interface ITaskContext {
  isAutoPlay: boolean;
  isFullScreen: boolean;
  currentFrame: number;
  openSelectId?: string;
  results: TResults;
  selectedBrandIndex?: number;
  setIsAutoPlay: Dispatch<SetStateAction<boolean>>;
  setIsFullScreen: Dispatch<SetStateAction<boolean>>;
  setCurrentFrame: Dispatch<SetStateAction<number>>;
  setOpenSelectId: Dispatch<SetStateAction<string | undefined>>;
  setResults: Dispatch<SetStateAction<TResults>>;
  setSelectedBrandIndex: Dispatch<SetStateAction<number | undefined>>;
  setVideoDuration: Dispatch<SetStateAction<number | undefined>>;
  videoDuration?: number;
}

export const TaskContext = React.createContext<ITaskContext>({
  isAutoPlay: true,
  isFullScreen: true,
  currentFrame: 10,
  results: {},
  setIsAutoPlay: emptyFn,
  setIsFullScreen: emptyFn,
  setCurrentFrame: emptyFn,
  setOpenSelectId: emptyFn,
  setResults: emptyFn,
  setSelectedBrandIndex: emptyFn,
  setVideoDuration: emptyFn,
});

const TaskProvider = ({ children }: any) => {
  const [currentFrame, setCurrentFrame] = React.useState(0);
  const [isAutoPlay, setIsAutoPlay] = React.useState(true);
  const [isFullScreen, setIsFullScreen] = React.useState(true);
  const [results, setResults] = React.useState<TResults>({});
  const [openSelectId, setOpenSelectId] = React.useState<string>();
  const [selectedBrandIndex, setSelectedBrandIndex] = React.useState<number>();
  const [videoDuration, setVideoDuration] = React.useState<number>();

  return (
    <TaskContext.Provider
      value={{
        isAutoPlay,
        isFullScreen,
        currentFrame,
        openSelectId,
        results,
        selectedBrandIndex,
        setIsAutoPlay,
        setIsFullScreen,
        setCurrentFrame,
        setOpenSelectId,
        setResults,
        setSelectedBrandIndex,
        setVideoDuration,
        videoDuration,
      }}
    >
      {children}
    </TaskContext.Provider>
  );
};

export default TaskProvider;
