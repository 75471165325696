export const NUMBER_OF_SECONDS = 60;

export enum EPosition {
  "Next to dartsbord, wall behind" = "Next to dartsbord, wall behind",
  "On shirt" = "On shirt",
  "Boarding (behind player, podium or under stage)" = "Boarding (behind player, podium or under stage)",
  "Microphones" = "Microphones",
  "Tables behind dartsplayers" = "Tables behind dartsplayers",
  "All the other" = "All the other",
}
