import React from "react";
import { formatTime } from "../../../../inc/data";
import { TaskContext } from "../../../../provider/task";
import { EPosition, NUMBER_OF_SECONDS } from "../../../../inc/constants";
import brands from "../../../../inc/brands";
import { ApiDataContext } from "../../../../provider/apiData";

const BRANDS = brands.map((brand) => brand.brand);

const BrandControl = () => {
  const { currentFrame, results, setResults, selectedBrandIndex } =
    React.useContext(TaskContext);
  const { currentTask } = React.useContext(ApiDataContext);
  const time = formatTime(currentFrame);
  const brand =
    selectedBrandIndex === undefined ? undefined : BRANDS[selectedBrandIndex];
  const isHome = React.useMemo(
    () =>
      currentTask && currentTask.videoFileName.toLowerCase().includes("yanmar"),
    [currentTask],
  );
  const POSITIONS = React.useMemo(
    () =>
      Object.keys(EPosition).filter((position) => {
        if (!isHome) {
          return !position.toLowerCase().includes("home only");
        }
        return true;
      }) as EPosition[],
    [isHome],
  );

  const timeResults = results[time];
  const brandResults: { [pos: string]: number } | undefined =
    brand && timeResults ? timeResults[brand] : undefined;

  const modifyFutureFrames = React.useCallback(
    (positionIndex: number, deltaSeconds: number) => {
      if (!brand) {
        return;
      }
      setResults((results) => {
        const newResults = { ...results };

        for (let i = currentFrame; i < NUMBER_OF_SECONDS; i++) {
          const newTime = formatTime(i);
          const newTimeResults = results[newTime] || {};
          const newBrandResults = newTimeResults[brand] || {};
          const position = POSITIONS[positionIndex];
          const newPositionResult = newBrandResults[position] || 0;

          newResults[newTime] = {
            ...newTimeResults,
            [brand]: {
              ...newBrandResults,
              [position]: newPositionResult + deltaSeconds,
            },
          };

          if (newResults[newTime][brand][position] < 1) {
            delete newResults[newTime][brand][position];
          }
        }

        return newResults;
      });
    },
    [POSITIONS, brand, currentFrame, setResults],
  );

  if (!brand || selectedBrandIndex === undefined) {
    return null;
  }

  const brandPositions = brands[selectedBrandIndex].positions;

  return (
    <div>
      <table>
        <tbody>
          {POSITIONS.map((pos, positionIndex) => (
            <tr key={pos}>
              <td style={{ paddingLeft: 8 }}>
                <span style={{ textDecoration: "underline", color: "gray" }}>
                  {String.fromCharCode(positionIndex + 97)}
                </span>{" "}
                {pos}
              </td>
              <td>
                <input
                  disabled={
                    !brandPositions.includes(
                      POSITIONS[positionIndex] as EPosition,
                    )
                  }
                  type="number"
                  id={`brandposition_${selectedBrandIndex}_${positionIndex}`}
                  value={`${
                    brandResults && brandResults[pos] ? brandResults[pos] : ""
                  }`}
                  style={{
                    width: 40,
                    fontSize: 20,
                  }}
                  onChange={(e) => {
                    const delta =
                      parseInt(e.currentTarget.value) -
                      (brandResults && brandResults[pos]
                        ? brandResults[pos]
                        : 0);
                    modifyFutureFrames(positionIndex, delta);
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <img
        src={`/img/${brand}.png`}
        alt={brand}
        style={{ maxWidth: "100%", marginTop: "1rem" }}
      />
    </div>
  );
};

export default React.memo(BrandControl);
