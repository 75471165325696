import React from "react";
import BrandControl from "./BrandControl";
import { formatTime } from "../../../inc/data";
import { NUMBER_OF_SECONDS } from "../../../inc/constants";
import { sum } from "lodash";
import { TaskContext } from "../../../provider/task";
import { ApiDataContext } from "../../../provider/apiData";
import VideoFrameVideo from "./VideoFrameVideo";
import BrandSelector from "./BrandSelector";
import "./index.css";
import brands from "../../../inc/brands";

const BRANDS = brands.map((brand) => brand.brand);

const VideoFrameControl = () => {
  const { currentTask } = React.useContext(ApiDataContext);
  const { currentFrame, results, setResults } = React.useContext(TaskContext);

  const isEmpty = React.useMemo(() => {
    const frameResults = results[formatTime(currentFrame)];
    return !frameResults || !sum(Object.values(frameResults));
  }, [currentFrame, results]);

  if (!currentTask) {
    return null;
  }

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        backgroundColor: "white",
        borderBottom: "1px solid #777",
        overflow: "hidden",
        alignItems: "stretch",
      }}
    >
      <div className="video-frame-video-container">
        <VideoFrameVideo />
      </div>
      <div style={{ overflow: "auto", height: "100%" }}>
        <div style={{ width: 360 }}>
          <div style={{ margin: 4, textAlign: "center" }}>
            <button
              disabled={isEmpty}
              style={{ fontSize: 18 }}
              onClick={() => {
                setResults((results) => {
                  const newResults = { ...results };
                  for (let i = currentFrame; i < NUMBER_OF_SECONDS; i++) {
                    const newTime = formatTime(i);
                    newResults[newTime] = {};
                  }
                  return newResults;
                });
              }}
            >
              Clea<span style={{ textDecoration: "underline" }}>r</span> all
            </button>
          </div>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {BRANDS.map((brand, brandIndex) => (
              <BrandSelector key={brand} brandIndex={brandIndex} />
            ))}
          </div>
          <BrandControl />
        </div>
      </div>
    </div>
  );
};

export default React.memo(VideoFrameControl);
