import "./index.css";

interface IBadgeProps {
  children: React.ReactNode;
  label?: string | number | null;
}

const Badge = ({ children, label }: IBadgeProps) => {
  return (
    <div className="badge-container">
      {children}
      {label ? <div className="badge">{label}</div> : null}
    </div>
  );
};

export default Badge;
