import React from "react";
import AdminView from "./view/AdminView";
import ProcessTaskView from "./view/ProcessTaskView";
import { Route, Routes } from "react-router-dom";

function App() {
  return (
    <Routes>
      <Route path="/">
        <Route path="/admin" element={<AdminView />} />
        <Route index element={<ProcessTaskView />} />
      </Route>
    </Routes>
  );
}

export default App;
