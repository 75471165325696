import React from "react";
import { KunciContext } from "kunci-react";
import { ApiDataContext } from "../../../../provider/apiData";
import { TaskContext } from "../../../../provider/task";
import "./index.css";

const VideoFrameVideo = () => {
  const { auth } = React.useContext(KunciContext);
  const { currentTask } = React.useContext(ApiDataContext);
  const { isAutoPlay, currentFrame, videoDuration, setVideoDuration } =
    React.useContext(TaskContext);
  const videoRef = React.useRef<HTMLVideoElement | null>(null);

  const { minute = 0 } = currentTask || {};

  React.useEffect(() => {
    const video = videoRef.current;
    if (!video) {
      console.warn("Video-ref not available after render?");
      return;
    }
    const currentTime = minute * 60 + currentFrame;
    video.currentTime = currentTime;

    // happens when video was finished
    if (video.paused && isAutoPlay) {
      video.play();
    }

    let handle: number;
    const drawingLoop = async (
      _timestamp: number,
      frame: VideoFrameCallbackMetadata,
    ) => {
      const frameSecond = Math.floor(frame.mediaTime);
      if (frameSecond > currentTime) {
        video.currentTime = currentTime;
      }
      if (video.duration !== videoDuration) {
        setVideoDuration(video.duration);
      }

      handle = video.requestVideoFrameCallback(drawingLoop);
    };

    handle = video.requestVideoFrameCallback(drawingLoop);
    return () => {
      if (handle) {
        video.cancelVideoFrameCallback(handle);
      }
    };
  }, [currentFrame, isAutoPlay, minute, setVideoDuration, videoDuration]);

  React.useEffect(() => {
    const video = videoRef.current;
    if (!video) {
      return;
    }
    if (isAutoPlay) {
      video.play().catch(console.log);
      return;
    }
    video.pause();
  }, [isAutoPlay]);

  return auth ? (
    <video
      className="video-frame-video"
      src={`${process.env.REACT_APP_API_URL}/video/${currentTask?.videoFileName}?bearer=${auth.bearer}`}
      muted
      preload="auto"
      autoPlay={isAutoPlay}
      ref={videoRef}
    />
  ) : null;
};

export default React.memo(VideoFrameVideo);
