import React, { Dispatch, SetStateAction } from "react";
import { emptyFn } from "../inc/data";
import { components } from "../types/openapi";

interface IApiDataContext {
  currentTask?: components["schemas"]["Task"] | null;
  setCurrentTask: Dispatch<
    SetStateAction<components["schemas"]["Task"] | null | undefined>
  >;
}

export const ApiDataContext = React.createContext<IApiDataContext>({
  setCurrentTask: emptyFn,
});

const ApiDataProvider = ({ children }: any) => {
  const [currentTask, setCurrentTask] = React.useState<
    components["schemas"]["Task"] | null
  >();

  return (
    <ApiDataContext.Provider
      value={{
        currentTask,
        setCurrentTask,
      }}
    >
      {children}
    </ApiDataContext.Provider>
  );
};

export default ApiDataProvider;
