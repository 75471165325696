import React from "react";
import { EPosition, NUMBER_OF_SECONDS } from "../../inc/constants";
import VideoFrameControl from "./VideoFrameControl";
import { axios } from "kunci-react";
import { formatTime } from "../../inc/data";
import { TaskContext } from "../../provider/task";
import { ApiDataContext } from "../../provider/apiData";
import Select from "react-select";
import { range } from "lodash";
import brands from "../../inc/brands";

const POSITIONS = Object.keys(EPosition);

const ProcessTaskView = () => {
  const { currentTask, setCurrentTask } = React.useContext(ApiDataContext);
  const {
    currentFrame,
    isAutoPlay,
    isFullScreen,
    openSelectId,
    results,
    selectedBrandIndex,
    setCurrentFrame,
    setIsAutoPlay,
    setIsFullScreen,
    setOpenSelectId,
    setResults,
    setSelectedBrandIndex,
    videoDuration,
  } = React.useContext(TaskContext);

  const reactSelectRef = React.useRef(null);

  const previousFrame = React.useCallback(() => {
    setCurrentFrame((currentFrame) =>
      currentFrame ? currentFrame - 1 : currentFrame,
    );
  }, [setCurrentFrame]);
  const nextFrame = React.useCallback(() => {
    setCurrentFrame((currentFrame) =>
      currentFrame >= NUMBER_OF_SECONDS - 1 ? currentFrame : currentFrame + 1,
    );
  }, [setCurrentFrame]);

  const finish = React.useCallback(() => {
    if (!window.confirm("Are you sure your want to submit your work?")) {
      return;
    }
    setCurrentTask(null);
    axios
      .request({
        method: "post",
        url: "/task/submit",
        data: { ...currentTask, result: results },
      })
      .then(() => {
        setCurrentTask(undefined);
        setResults({});
        setCurrentFrame(0);
      })
      .catch((err) => {
        window.alert(err.message);
        console.log(err);
      });
  }, [currentTask, results, setCurrentFrame, setCurrentTask, setResults]);

  React.useEffect(() => {
    const keyDownListener = (e: KeyboardEvent) => {
      // Switch brand?
      const node = e.target ? (e.target as HTMLInputElement) : undefined;
      const isFormControlInput = node && node.nodeName === "INPUT" && !e.altKey;
      const hasNumberInputFocus = isFormControlInput && node.type === "number";

      switch (e.key.toLowerCase()) {
        case "escape":
          if (isFormControlInput) {
            node!.blur();
          }
          break;

        case "r": // clea_r_ all
          if (isFormControlInput) {
            return;
          }
          e.preventDefault();
          setResults((results) => {
            const newResults = { ...results };
            for (let i = currentFrame; i < NUMBER_OF_SECONDS; i++) {
              const newTime = formatTime(i);
              newResults[newTime] = {};
            }
            return newResults;
          });
          break;

        case "u": //A_u_toplay
          if (isFormControlInput) {
            return;
          }
          setIsAutoPlay((isAutoPlay) => !isAutoPlay);
          break;

        case "s": // fini_s_h
          if (isFormControlInput) {
            return;
          }
          e.preventDefault();
          finish();
          break;

        case "arrowright": // Next
          if (isFormControlInput) {
            return;
          }
          e.preventDefault();
          nextFrame();
          break;

        case "arrowleft": // Previous
          if (isFormControlInput) {
            return;
          }
          e.preventDefault();
          previousFrame();
          break;

        default:
          // brand?
          const brandIndex = e.key.charCodeAt(0) - 49;
          const { brand } = brands[brandIndex];
          if (brand && !hasNumberInputFocus) {
            e.preventDefault();
            setSelectedBrandIndex(brandIndex);
            return;
          }
          // position?
          const positionIndex = e.key.charCodeAt(0) - 97;
          const position = POSITIONS[positionIndex];
          if (position) {
            const input = document.getElementById(
              `brandposition_${selectedBrandIndex}_${positionIndex}`,
            );
            if (input) {
              input.focus();
            }
          }
      }
    };
    window.addEventListener("keydown", keyDownListener);
    return () => {
      window.removeEventListener("keydown", keyDownListener);
    };
  }, [
    currentFrame,
    finish,
    nextFrame,
    previousFrame,
    setIsAutoPlay,
    setCurrentFrame,
    setOpenSelectId,
    setResults,
    setSelectedBrandIndex,
    selectedBrandIndex,
  ]);

  const currentTaskNumberOfSeconds = React.useMemo(() => {
    if (!currentTask) {
      return 0;
    }
    const firstSecond = currentTask.minute * 60;
    let lastSecond = currentTask.minute * 60 + NUMBER_OF_SECONDS;
    if (videoDuration && videoDuration > 0 && videoDuration < lastSecond) {
      lastSecond = Math.ceil(videoDuration);
    }
    return lastSecond - firstSecond;
  }, [currentTask, videoDuration]);

  const options = React.useMemo(() => {
    if (!currentTaskNumberOfSeconds) {
      return [];
    }

    return currentTask
      ? range(0, currentTaskNumberOfSeconds).map((second) => {
          return {
            label: formatTime(currentTask.minute * 60 + second),
            value: second,
          };
        })
      : [];
  }, [currentTask, currentTaskNumberOfSeconds]);

  const selectId = "videoFrameControl";
  const menuIsOpen = openSelectId === selectId;
  // if the current brand control is open, make sure it is also focussed
  React.useEffect(() => {
    if (menuIsOpen && reactSelectRef.current) {
      // @ts-ignore
      reactSelectRef.current.focus();
    }
  }, [menuIsOpen]);

  React.useEffect(() => {
    function onFullscreenchange() {
      if (document.fullscreenElement) {
        if (!isFullScreen) {
          setIsFullScreen(true);
        }
        return;
      }

      if (isFullScreen) {
        setIsFullScreen(false);
      }
    }

    document.addEventListener("fullscreenchange", onFullscreenchange);
    return () => {
      document.removeEventListener("fullscreenchange", onFullscreenchange);
    };
  }, [isFullScreen, setIsFullScreen]);

  if (!currentTask) {
    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {currentTask === null ? (
          <div>⌛</div>
        ) : (
          <button
            autoFocus
            onClick={() => {
              if (isFullScreen) {
                document.documentElement.requestFullscreen();
              }
              axios
                .post("/task/claim")
                .then((res) => {
                  setCurrentTask(res.data);
                })
                .catch((_err) => {
                  window.alert("Task can not be claimed. All finished?");
                });
            }}
          >
            Claim next task
          </button>
        )}
      </div>
    );
  }

  return (
    <div
      style={{
        backgroundColor: "#CCC",
        maxWidth: "100%",
        height: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <VideoFrameControl />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: 20,
        }}
      >
        <button
          disabled={currentFrame === 0}
          onClick={previousFrame}
          style={{ marginRight: "2rem" }}
        >
          <span style={{ textDecoration: "underline" }}>←</span> Previous
        </button>
        <div
          style={{ display: "flex", marginLeft: "auto", marginRight: "auto" }}
        >
          <label
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                flex: 1,
                overflow: "hidden",
                padding: 8,
                fontWeight: "bold",
                borderRight: "1px solid white",
                marginRight: 8,
              }}
            >
              {formatTime(currentTask.minute * 60)} -{" "}
              {formatTime(currentTask.minute * 60 + (NUMBER_OF_SECONDS - 1))}{" "}
              {currentTask.videoFileName}
            </div>
            <span>Frame</span>
            <div style={{ marginLeft: 8 }}>
              <Select
                options={options}
                menuIsOpen={openSelectId === selectId}
                menuPortalTarget={document.body}
                menuPlacement="top"
                onChange={(selectedOption) => {
                  if (!selectedOption) {
                    return;
                  }
                  setCurrentFrame(selectedOption.value);
                  setOpenSelectId(undefined);
                }}
                onMenuOpen={() => {
                  setOpenSelectId(selectId);
                }}
                onMenuClose={() => {
                  setOpenSelectId((openSelectId) =>
                    openSelectId === selectId ? undefined : openSelectId,
                  );
                }}
                ref={reactSelectRef}
                value={options.find((option) => option.value === currentFrame)}
              />
            </div>
          </label>
          <label
            style={{
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              marginLeft: 32,
              justifyContent: "center",
            }}
          >
            A
            <span
              style={{
                textDecoration: "underline",
              }}
            >
              u
            </span>
            toplay
            <div>
              <input
                type="checkbox"
                checked={isAutoPlay}
                onChange={(e) => {
                  setIsAutoPlay(e.currentTarget.checked);
                }}
              />
            </div>
          </label>
          <label
            style={{
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              marginLeft: 32,
              justifyContent: "center",
            }}
          >
            Is Full Screen
            <div>
              <input
                type="checkbox"
                checked={isFullScreen}
                onChange={(e) => {
                  if (e.currentTarget.checked) {
                    document.documentElement.requestFullscreen();
                  } else {
                    document.exitFullscreen();
                  }
                  setIsFullScreen(e.currentTarget.checked);
                }}
              />
            </div>
          </label>
        </div>
        {currentFrame >= currentTaskNumberOfSeconds - 1 ? (
          <button
            style={{
              marginLeft: "auto",
              backgroundColor: "green",
              color: "white",
            }}
            onClick={finish}
          >
            Fini<span style={{ textDecoration: "underline" }}>s</span>h
          </button>
        ) : (
          <button
            style={{
              marginLeft: "auto",
            }}
            onClick={nextFrame}
          >
            Next <span style={{ textDecoration: "underline" }}>→</span>
          </button>
        )}
      </div>
    </div>
  );
};

export default React.memo(ProcessTaskView);
