import React from "react";
import { axios, KunciContext } from "kunci-react";
import { intervalToDuration } from "date-fns";
import { components } from "../../types/openapi";

const AdminView = () => {
  const { auth } = React.useContext(KunciContext);

  const [videos, setVideos] = React.useState<
    components["schemas"]["Video"][] | null
  >();

  React.useEffect(() => {
    axios.get("/admin/video").then((res) => setVideos(res.data));
  }, []);

  return auth && videos ? (
    <div className="m-1">
      <h1>Available video's</h1>
      <table border={1} style={{ borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <th>File name</th>
            <th>Duration</th>
            <th>Progress</th>
            <th>In progress by</th>
          </tr>
        </thead>
        <tbody>
          {videos.map((video) => {
            const status = video.totalTaskCount > 0 ? "old" : "new";
            const duration = intervalToDuration({
              start: 0,
              end: video.duration * 1000,
            });
            const zeroPad = (num: number) => String(num).padStart(2, "0");
            return (
              <tr key={video.fileName}>
                <td>{video.fileName}</td>
                <td style={{ textAlign: "center", padding: 8 }}>
                  {video.duration
                    ? `${zeroPad(duration.hours || 0)}:${zeroPad(
                        duration.minutes || 0,
                      )}:${zeroPad(duration.seconds || 0)}`
                    : "Not available"}
                </td>
                <td style={{ textAlign: "center", padding: 8 }}>
                  {video.totalTaskCount
                    ? video.totalTaskCount === video.completedTaskCount
                      ? "Finished"
                      : `${video.completedTaskCount} / ${video.totalTaskCount}`
                    : "Not started"}
                </td>
                <th>
                  {video.currentUserNames.length
                    ? video.currentUserNames.join(", ")
                    : "-"}
                </th>
                <td>
                  {status === "new" ? (
                    <button
                      onClick={() => {
                        setVideos(null);
                        axios
                          .post(`/admin/video/${video.fileName}/start`)
                          .then((_res) => {
                            // console.log(res.data);
                            window.location.reload();
                          })
                          .catch((e) => {
                            window.alert(e.message);
                          });
                      }}
                    >
                      Start
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        window.location.href = `${process.env.REACT_APP_API_URL}/excel/${video.fileName}?bearer=${auth.bearer}`;
                      }}
                    >
                      Show results
                    </button>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <hr />
      <button
        className="btn m-1"
        onClick={() => {
          window.open(
            `${process.env.REACT_APP_API_URL}/excel/dump?bearer=${auth.bearer}`,
          );
        }}
      >
        Download task export
      </button>
    </div>
  ) : (
    <div>⌛</div>
  );
};

export default React.memo(AdminView);
