import React from "react";
import { TaskContext } from "../../../../provider/task";
import "./index.css";
import Badge from "../../../../components/Badge";
import { formatTime } from "../../../../inc/data";
import { sum } from "lodash";
import brands from "../../../../inc/brands";

const BRANDS = brands.map((brand) => brand.brand);

interface IBrandSelectorProps {
  brandIndex: number;
}
const BrandSelector = ({ brandIndex }: IBrandSelectorProps) => {
  const { selectedBrandIndex, setSelectedBrandIndex, results, currentFrame } =
    React.useContext(TaskContext);

  const brand = BRANDS[brandIndex];

  const label = React.useMemo(() => {
    const time = formatTime(currentFrame);
    const timeResults = results[time];
    const brandResults: { [pos: string]: number } | undefined =
      brand && timeResults ? timeResults[brand] : undefined;
    return brandResults ? sum(Object.values(brandResults)) : undefined;
  }, [brand, currentFrame, results]);

  const classes = ["brand-selector"];
  if (brandIndex === selectedBrandIndex) {
    classes.push("brand-selector--is-selected");
  }

  return (
    <div
      key={brand}
      className={classes.join(" ")}
      onClick={() => {
        setSelectedBrandIndex(brandIndex);
      }}
    >
      <Badge label={label}>
        <img src={`/img/${brand}.png`} alt={brand} />
      </Badge>
    </div>
  );
};

export default React.memo(BrandSelector);
